import { AppDomainConstant } from '../app-domain.constant';

export class AutenticacionAPIConstant {

  static readonly AUTENTICACION_API: string = 'seguridad/';
  static readonly AUTENTICACION_API_LOGIN: string = 'acceso-login/';
  static readonly APPLICATION_TYPE: string = "BACKOFFICE";
  static readonly LOCAL_NEW_CORE: string = 'api/v1/users-management/';
  static readonly ADMIN_PRIVILAGE: string="api/v1/privileges";
  static readonly ESTRUCTURA_COMERCIAL: string="api/v1/estructura-comercial";
  static readonly FINANCIERA_CONTABLE: string="api/v1/financiera-contable";

  static readonly URL_DOCUMENT: string =
  AppDomainConstant.URI_GATEWAY +
  AutenticacionAPIConstant.LOCAL_NEW_CORE

  static readonly URL_LOGIN: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/sign-in';

  static readonly URL_BIENVENIDA: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.AUTENTICACION_API +
    'bienvenida';

  static readonly URL_LOGIN_MFA: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/sign-in-mfa';

  static readonly URL_PRIVILEGES_ROLES: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.ADMIN_PRIVILAGE +
    '/users/by-identification';

  static readonly URL_ASSOCIATE_SOFTWARE: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/associate-software-token';

  static readonly URL_VERIFY_SOFTWARE: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/verify-software-token';

  static readonly URL_SIGNOUT: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/sign-out';

  static readonly URL_REFRESH_TOKEN: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'login/refresh-tokens';

  static readonly URL_GET_PUNTO_VENTA: string =
  AppDomainConstant.URI_GATEWAY +
  AutenticacionAPIConstant.ESTRUCTURA_COMERCIAL +
    '/terminal/filtrar';

  static readonly URL_GET_CAJA: string =
  AppDomainConstant.URI_GATEWAY +
  AutenticacionAPIConstant.FINANCIERA_CONTABLE +
    '/gestion-caja/mac';
  
  static readonly URL_ACTUALIZAR_CONTRASENA_USUARIO: string =
    AppDomainConstant.URI_GATEWAY +
    AutenticacionAPIConstant.LOCAL_NEW_CORE +
    'credentials/change-password';
}
