/**
 * Clase constante que contiene todo los ROUTER del app
 */
export class RouterConstant {
  public static readonly ROUTER_BALANCEADOR_PASARELA_PAGO: string =
    "balanceador-pasarela-pago";

  /** Router para los componentes de operaciones caja */
  public static readonly ROUTER_OPERACIONES_CAJA: string = "operaciones-caja";

  /** Router para el componente de pago de premios */
  public static readonly ROUTER_PAGO_PREMIOS: string = "pago-premios";

  /** Router para el componente de recaudo cartera */
  public static readonly ROUTER_RECAUDO_CARTERA: string = "recaudo-cartera";

  /** Router para el componente de ingreso de caja */
  public static readonly ROUTER_INGRESO_CAJA: string = "ingreso-caja";

  /** Router para el componente de egreso de caja */
  public static readonly ROUTER_EGRESO_CAJA: string = "egreso-caja";

  /** Router para los componentes de operaciones caja */
  public static readonly ROUTER_CAJA_OPERACIONES: string = "caja-operaciones";

  /** Router para el modulo del LOGIN */
  public static readonly ROUTER_LOGIN: string = "login";

  /** Router para el modulo del PAGINAS DE ERRORES */
  public static readonly ROUTER_ERROR: string = "error";

  /** Router para la pagina de error cuando el usuario no tiene permisos */
  public static readonly ROUTER_DENEGADO: string = "denegado";

  /** Router que se utiliza cuando el user esta autenticado */
  public static readonly ROUTER_AUTENTICADO: string = "autenticado";

  /** Router para la pagina BIENVENIDA */
  public static readonly ROUTER_BIENVENIDA: string = "bienvenida";

  /** Nombre del Router para la pagina de admin cuenta del user */
  public static readonly ROUTER_CUENTA_USUARIO: string = "cuenta-usuario";

  /** Router para el modulo de LOTERIAS */
  public static readonly ROUTER_LOTERIAS: string = "loterias";

  /** Router para el modulo de CALENDARIOS SORTEOS */
  public static readonly ROUTER_CALENDARIOS_SORTEOS: string =
    "calendario-sorteos";

  /** Router para el modulo de ESCRUTAR */
  public static readonly ROUTER_ESCRUTAR: string = "escrutar";

  public static readonly ROUTER_ESCRUTAR_CONSULTAR: string =
    "consultar-escrutinio";

  public static readonly ROUTER_ESCRUTAR_REVERSAR: string =
    "consultar-escrutinio-reversar";

  /** Router para la pagina de numeros excluidos*/
  public static readonly ROUTER_NUMEROS_EXCLUIDOS: string = "numeros-excluidos";

  /** Router para la pagina de parametros premios*/
  public static readonly ROUTER_PARAMETRO_PREMIO: string = "parametro-premio";

  /** Router para la pagina de administracion de roles */
  public static readonly ROUTER_ROLES: string = "roles";

  /** Router para la pagina de administracion de parametros de cartera */
  public static readonly ROUTER_PARAMETROS_CARTERA: string =
    "parametros-cartera";

  /** Router para la pagina de administracion de menús */
  public static readonly ROUTER_MENU: string = "menu";

  /** Router para la pagina de administracion de valores para los domicilios */
  public static readonly ROUTER_VALORES: string = "valores";

  /** Router para la pagina de administracion delivery */
  public static readonly ROUTER_DELIVERY: string = "delivery";

  /** Router para la pagina de configuracion loteria virtual */
  public static readonly ROUTER_CONFIGURACION_LOTERIA_VIRTUAL: string =
    "loteria-virtual";

  /** Router para la pagina de configuracion plan comisiones */
  public static readonly ROUTER_CONFIGURACION_PLAN_COMISIONES: string =
    "planes-comisiones";

  /** Router para la pagina de numero-ganador */
  public static readonly ROUTER_NUMERO_GANADOR: string = "numero-ganador";

  /** Router para el modulo de ESCRUTINIO */
  public static readonly ROUTER_ESCRUTINIO: string = "escrutinio";

  public static readonly ROUTER_JSA: string = "jsa";

  /** Router para el modulo ADMINISTRATIVO */
  public static readonly ROUTER_ADMINISTRACION: string = "administracion";

  /** Router para los componentes de solicitudes */
  public static readonly ROUTER_SOLICITUDES: string = "solicitudes/";

  /** Router para los componentes de configuracion */
  public static readonly ROUTER_CONFIGURACIONES: string = "configuracion/";

  /** Router para los componentes de domicilios */
  public static readonly ROUTER_DOMICILIOS: string = "domicilios/";

  /** Router para los componentes del modulo de papeleria */
  public static readonly ROUTER_PAPELERIA: string = "papeleria/";

  /** Router para los componentes del modulo de horarios */
  public static readonly ROUTER_HORARIO: string = "horario/";

  /** Router para la pagina de programacion horario */
  public static readonly ROUTER_PROGRAMACION_HORARIO: string =
    "programacion-horario";

  /** Router para la pagina de programacion asesores */
  public static readonly ROUTER_PROGRAMACION_ASESORES: string =
    "programacion-asesores";

  /** Router para la pagina de inventario general papeleria */
  public static readonly ROUTER_INVENTARIO_GENERAL_PAPELERIA: string =
    "inventario-general";

  public static readonly ROUTER_SINIESTRO_PAPELERIA: string = "siniestro";

  /** Router para la pagina de inventario general papeleria */
  public static readonly ROUTER_INVENTARIO_CAJA_PAPELERIA: string =
    "inventario-caja";

  /** Router para la pagina de inventario general papeleria */
  public static readonly ROUTER_ASIGNAR_PAPELERIA: string = "asignar-papeleria";

  /** Router para la pagina de inventario oficina papeleria */
  public static readonly ROUTER_INVENTARIO_OFICINA_PAPELERIA: string =
    "inventario-oficina";

  /** Router para el modulo de gestion de empresas */
  public static readonly ROUTER_GESTION_EMPRESAS: string = "gestion-empresas";

  /** Router para el modulo de gestion de distribuidores */
  public static readonly ROUTER_GESTION_DISTRIBUIDORES: string =
    "gestion-distribuidores";

  /** Router para el modulo de gestion de operadores */
  public static readonly ROUTER_GESTION_OPERADORES: string =
    "gestion-operadores";

  /** Router para el componente de pagos giros */
  public static readonly ROUTER_PAGOS_GIROS: string = "giros/pagos-giros";

  /** Constante para navegar al modulo de LOGIN */
  public static readonly NAVIGATE_LOGIN: string = `/${RouterConstant.ROUTER_LOGIN}`;

  /** Constante para navegar a la pagina de PERMISOS DENEGADO */
  public static readonly NAVIGATE_DENEGADO: string = `/${RouterConstant.ROUTER_ERROR}/${RouterConstant.ROUTER_DENEGADO}`;

  /** Constante para navegar a la pagina de BIENVENIDA */
  public static readonly NAVIGATE_BIENVENIDA: string = `/${RouterConstant.ROUTER_AUTENTICADO}/${RouterConstant.ROUTER_BIENVENIDA}`;

  /** Constante para navegar a la pagina de ADMIN CUENTA USER */
  public static readonly NAVIGATE_CUENTA_USUARIO: string = `/${RouterConstant.ROUTER_AUTENTICADO}/${RouterConstant.ROUTER_CUENTA_USUARIO}`;

  /** Constante para navegar a la pagina de CALENDARIOS SORTEOS */
  public static readonly NAVIGATE_CALENDARIO_SORTEO: string =
    "/autenticado/escrutinio/calendario-sorteos";

  /** Constante para navegar a la pagina de LOTERIAS */
  public static readonly NAVIGATE_LOTERIAS: string =
    "/autenticado/escrutinio/loterias";

  /** Constante para navegar a la pagina de GESTION EMPRESA */
  public static readonly NAVIGATE_GESTION_EMPRESA: string =
    "/autenticado/administracion/multinivel/gestion-empresas";

  /** Constante para navegar a la pagina de CONFIGURACION ASOCIACION */
  public static readonly NAVIGATE_GESTION_DISTRIBUIDORES: string =
    "/autenticado/administracion/multinivel/gestion-distribuidores";

  /** Constante para navegar a la pagina de CONFIGURACION USUARIOS */
  public static readonly NAVIGATE_USUARIOS: string =
    "/autenticado/administracion/configuracion/configuracion-usuario";

  /** Router para el modulo de MULTINIVEL */
  public static readonly ROUTER_MULTINIVEL: string = "multinivel/";

  /** Router para el modulo de ROUTER_CONFIGURACION */
  public static readonly ROUTER_CONFIGURACION: string = "configuracion/";

  /** Router para el modulo de ROUTER_CONFIGURACION */
  public static readonly ROUTER_FEDERACION: string = "federacion/";

  /** Router para el modulo de CONFIGURACION_USUARIO */
  public static readonly ROUTER_CONFIGURACION_USUARIO: string =
    "configuracion-usuario";

  /** Router para el modulo de GESTION_USUARIO */
  public static readonly ROUTER_GESTION_USUARIO: string = "gestion-usuario";

  /** Router para la pagina de administracion de consignaciones */
  public static readonly ROUTER_CONSIGNACIONES: string = "consignaciones";

  /** Router para los componentes de caja */
  public static readonly ROUTER_CAJA_CARTERA: string = "caja-cartera/";

  /** Router para los componentes de caja */
  public static readonly ROUTER_CAJA: string = "recursos-caja/";

  /** Router para los componentes de cartera */
  public static readonly ROUTER_CARTERA: string = "cartera";

  /** Router para los componentes de cartera */
  public static readonly ROUTER_CARTERA_REPORTE: string = "cartera-reporte";

  /** Router para los componentes de gestión de cartera */
  public static readonly ROUTER_GESTION_CARTERA: string = "gestion-cartera";

  /** Router para los componentes de ajuste de cartera */
  public static readonly ROUTER_AJUSTE_CARTERA: string = "ajuste-cartera";

  /** Router para los componentes de gestión notificacionde cartera */
  public static readonly ROUTER_GESTION_NOTIFICACION: string =
    "gestion-notificacion";

  /** Router para los componentes de notificaciones */
  public static readonly ROUTER_NOTIFICACIONES: string = "notificaciones";

  /** Router para la pagina de configuracion  recaudos*/
  public static readonly ROUTER_CONFIGURACION_RECAUDOS: string = "recaudos";

  /** Router para la pagina de configuracion de parametrizacion*/
  public static readonly ROUTER_CONFIGURACION_PARAMETRIZACION: string =
    "parametrizaciones";

  /** Router para los componentes de crear caja */
  public static readonly ROUTER_CREAR_CAJA: string = "crear-caja";

  /** Router para los componentes de tipo pago */
  public static readonly ROUTER_TIPOS_PAGOS: string = "tipos-pagos";

  /** Router para los componentes de cierre de cajas */
  public static readonly ROUTER_CIERRE_CAJA: string = "cierre-caja";

  /** Router para los componentes de apertura de cajas */
  public static readonly ROUTER_APERTURA_CAJA: string = "apertura-caja";

  /** Router para los componentes de pago premio */
  public static readonly ROUTER_PAGO_PREMIO: string = "pago-premios";

  /** Router para los componentes de registro de ingreso */
  public static readonly ROUTER_REGISTRO_INGRESO: string = "registro-ingreso";

  /** Router para los componentes de registro de egresos */
  public static readonly ROUTER_REGISTRO_EGRESO: string = "registro-egreso";

  /** Router para los componentes de reporte cartera */
  public static readonly ROUTER_REPORTE_CARTERA: string = "reporte-cartera";

  /** Constante para navegar a la pagina de apertura caja*/
  public static readonly NAVIGATE_APERTURA_CAJA: string =
    "/autenticado/administracion/recursos-caja/apertura-caja";

  /** Router para el componentes que gestionan los tipos de movimientos contables */
  public static readonly ROUTER_GESTION_TIPOS_MOVIMIENTOS_CONTABLES: string =
    "tipos-movimientos/gestion";

  /** Router para el componente que reinicia el consecutivo de los tipos de movimientos */
  public static readonly ROUTER_REINICIO_CONSECUTIVOS: string =
    "tipos-movimientos/reinicio-consecutivos";

  /** Router para el componente que administra la solicitud de tipos de movimientos contables */
  public static readonly ROUTER_SOLICITUD: string =
    "tipos-movimientos/solicitudes";

  /** Constante para navegar a la pagina de PAGOS GIROS */
  public static readonly NAVIGATE_PAGOS_GIROS: string = `/${RouterConstant.ROUTER_AUTENTICADO}/administracion/${RouterConstant.ROUTER_PAGOS_GIROS}`;

  /** Router para la pagina de configuracion giros */
  public static readonly GIROS: string = "giros/";

  /** Router para los componente de pagar giros */
  public static readonly PAGAR_GIROS: string = "pagos-giros";

  /** Router para los componente de consultar giros */
  public static readonly CONSULTAR_GIROS: string = "consultar-giros";

  /** Router para los componente de enviar giros */
  public static readonly ENVIAR_GIROS: string = "enviar-giros";

  /** Router para las plantillas productos topes maximos */
  public static readonly PLANTILLA_PRODUCTOS: string = "plantilla-productos";

  /** Router para las plantillas de anulaciones */
  public static readonly ANULACIONES: string = "anulaciones";

  /** Router para las plantillas de gestion de portafolio */
  public static readonly PORTAFOLIO: string = "portafolio";

  public static readonly PARAMETROS_CONTABLES: string = "parametros";

  public static readonly CONFIGURACION_CONTABLE_PRODUCTOS: string =
    "configuracion-contable-productos";

  /**Router para modulo de configuración impresion */
  public static readonly ROUTER_CONFIGURACION_IMPRESION: string =
    "configuracion-impresion";

  /**Router para modulo de aprobación de configuración impresion */
  public static readonly ROUTER_APROBACION_CONFIGURACION_IMPRESION: string =
    "aprobacion-configuracion-impresion";

  public static readonly ROUTER_CONSULTAR_PAPELERIA: string =
    "consultar-inventario-papeleria";

  public static readonly CONFIGURACION_RIFAS: string = "configurar-rifa";

  /**Router para modulo de gestión comercial */
  public static readonly ROUTER_GESTION_COMERCIAL: string =
    "gestion-comercial/";

  /**Router para el componente de división política */
  public static readonly GESTION_POLITICA: string = "gestion-politica";

  /**Router para el componente de estructura comercial */
  public static readonly ESTRUCTURA_COMERCIAL: string = "estructura-comercial";

  /**Router para el componente de gestión terminales */
  public static readonly GESTION_TERMINALES: string = "gestion-terminales";

  /** Router para el modulo de grupo empresarial */
  public static readonly ROUTER_GRUPO_EMPRESARIAL: string = "grupo-empresarial";

  /** Router para el modulo de gestion de empresas */
  public static readonly ROUTER_GESTION_EMPRESASV2: string =
    "gestion-empresasV2";

  /** Router para el modulo de gestion comercializadores */
  public static readonly ROUTER_GESTION_COMERCIALIZADORES: string =
    "gestion-comercializadores";

  /** Router para el modulo de gestion distribuidores */
  public static readonly ROUTER_GESTION_DISTRIBUIDORESV2: string =
    "gestion-distribuidoresV2";

  public static readonly ROUTER_LOTERIA: string = "loteria/";

  public static readonly ROUTER_LOTERIA_FISICA: string = "loteria-fisica/";

  public static readonly ROUTER_LOTERIA_VIRTUAL: string = "loteria-virtual/";

  public static readonly ROUTER_ADMINISTRACION_LOTERIA: string =
    "administrar-loterias";

  public static readonly ROUTER_PARAMETRIZACION_SORTEOS: string =
    "parametrizacion-sorteos";

  public static readonly ROUTER_CARGA_BILLETES: string = "carga-billetes";

  public static readonly ROUTER_ASIGNACION_BILLETES: string =
    "asignacion-billetes";
  public static readonly ROUTER_ADMINISTRAR_FRACCIONES: string =
    "administrar-fracciones";

  public static readonly ROUTER_PREMIOS_LOTERIAS: string = "premios";

  public static readonly ROUTER_DEVOLUCION_LOTERIAS: string =
    "devolucion-loterias";

  /**Router para modulo de consulta de auditoria */
  public static readonly ROUTER_AUDITORIA: string = "auditoria";

  /**Router para componente de auditoria de configuraciones */
  public static readonly ROUTER_AUDIT_CONFIG: string = "configuraciones";

  public static readonly ROUTER_AUDIT_AUTENTICACIONES: string = "autenticacion";

  public static readonly ROUTER_AJUSTE_DEVOLUCION_VENTA: string =
    "ajuste-devolucion-venta";

  public static readonly ROUTER_ARCHIVO_DEVOLUCION_PARCIAL: string =
    "archivo-devolucion-parcial";

  public static readonly ROUTER_DEVOLUCION_TOTAL_LOTERIAS: string =
    "archivo-devolucion-total";

  public static readonly ROUTER_ASIGNAR_FECHAS_LOT_SOR: string =
    "asignar-fecha";

  /**Router para modulo de control de la operacion */
  public static readonly ROUTER_CONTROL_OPERACION: string =
    "control-operacion/";

  /**Router para el componente de parametrizacion productos */
  public static readonly PARAMETRIZACION_PRODUCTOS: string =
    "parametrizacion-productos";

  public static readonly CONFIGURACION_SUBPRODUCTOS: string =
    "configuracion-subproductos";

  public static readonly ROUTER_AUTORIZACION_SOLICITUDES: string =
    "autorizacion-solicitudes";

  public static readonly AJUSTES_COLILLAS: string = "ajustes-colillas";

  public static readonly ADMINISTRAR_VENDEDORES = "administrar-vendedores";

  public static readonly CONTRATOS_ALIADOS: string = "contrato-aliado";

  public static readonly ESTADO_CONFIGURACION_PRODUCTOS: string =
    "estado-productos";

  public static readonly CATEGORIA_PRODUCTO: string = "categoria-producto";

  public static readonly TIPO_COMISION: string = "tipo-comision";

  public static readonly ROUTER_LIBRO_DIARIO: string = "libro-diario";

  public static readonly ROUTER_PROGRAMAR_PAGO: string = "programar-pago";

  public static readonly ROUTER_CARGAR_RESULTADOS_LOTERIAS: string =
    "cargar-resultados-loteria";

  public static readonly AJUSTES_ANULACIONES: string = "ajustes-anulaciones";

  public static readonly ROUTER_ANULACION_CAJA: string = "anulacion-caja";

  public static readonly ROUTER_REPORTES: string = "reportes";

  public static readonly ROUTER_CAJA_EGRESOS: string = "caja-egresos";

  public static readonly ROUTER_CAJA_APROBACION_PG: string =
    "aprobacion-pago-servicios-p";

  public static readonly ROUTER_PARAMETRIZACIONES: string =
    "parametrizaciones/:modulo/:tabla-parametrica";

  public static readonly ROUTER_PARAMETRIZACION_CONCEPTOS: string =
    "parametrizacion-conceptos";

  public static readonly ROUTER_PARAMETRIZACION_INFO_LEGAL: string =
    "parametrizacion-info-legal";

  public static readonly ROUTER_PARAMETRIZACION_COMPROBANTE_VENTA: string =
    "parametrizacion-comprobante-venta";

  public static readonly ROUTER_TRASLADOS: string = "traslados";

  public static readonly ROUTER_PROGRAMAR_TRASLADOS: string =
    "programar-traslados";

  public static readonly ROUTER_INGRESOS_MERCANTILES: string =
    "ingresos-mercantiles";

  public static readonly MOTIVOS_ANULACIONES: string = "motivos-anulaciones";
  public static readonly VISTA_PRODUCTOS_VENTAS: string = "vista-productos-jsa";
  public static readonly CONFIGURAR_VISTA_PRODUCTOS_VENTAS: string =
    "configurar-vista-productos-jsa";

  public static readonly CONFIRMAR_AJUSTES: string = "confirmar-ajustes";

  public static readonly GESTION_CONTRATOS_COLOCADORES: string =
    "gestion-contratos-colocadores";

  public static readonly ROUTER_CONCILIACIONES: string = "conciliaciones";

  public static readonly ROUTER_CHANCE: string = "chance/";

  public static readonly ROUTER_ISC: string = "isc/";

  public static readonly ROUTER_CONFIG_CHANCE: string = "configuracion-base";

  public static readonly ROUTER_RIFA: string = "rifa/";

  public static readonly ROUTER_CONFIG_RIFA: string = "configuracion-rifa";

  public static readonly ROUTER_GESTION_EXCEDENTES_MERCANTILES: string =
    "gestion-excedente-mercantiles";

  public static readonly ROUTER_GESTION_EXCEDENTES_MERCANTILES_ANUAL: string =
    "gestion-excedente-mercantil-anual";

  public static readonly ROUTER_ICC: string = "icc/";

  public static readonly ROUTER_DOBLE_ACIERTO: string = "doble-acierto/";

  public static readonly ROUTER_REPORTE_LIQUIDACIONES: string =
    "reporte-liquidaciones";

  public static readonly ROUTER_REPORTE_THOMAS: string = "reporte-thomas";

  public static readonly ROUTER_TOPES_GENERAL: string = "topes-general";

  public static readonly ROUTER_TOPES: string = `topes-productos/${RouterConstant.ROUTER_TOPES_GENERAL}`;

  public static readonly ANULACION_COLILLAS: string = "anulacion-colillas";

  /**Router para modulo de topes productoss */
  public static readonly ROUTER_TOPES_PRODUCTOS: string = "topes-producto/";

  /** Router para la pagina de tope colocadores */
  public static readonly ROUTER_TOPE_COLOCADORES: string = "tope-colocadores";

  /** Router para la pagina de tope anulaciones */
  public static readonly ROUTER_TOPE_ANULACIONES: string = "tope-anulaciones";

  public static readonly ROUTER_BUC: string = "buc/";
  public static readonly ROUTER_MOD_BUC: string = "buc";
  public static readonly ROUTER_MOD_GESTION_CLIENTES: string =
    "gestion-clientes/";
  public static readonly ROUTER_MOD_ADMINISTRACION: string = "administracion/";
  public static readonly ROUTER_DEPMUN: string = "departamento-municipio";
  public static readonly ROUTER_GESTION_CLIENTES_BUC: string =
    "gestion-clientes-buc/:idParametro";
  public static readonly ROUTER_TIPO_TERMINOS: string = "tipo-terminos";
  public static readonly ROUTER_URL_GESTION_CLIENTES: string =
    RouterConstant.ROUTER_MOD_GESTION_CLIENTES +
    RouterConstant.ROUTER_GESTION_CLIENTES_BUC;
  public static readonly ROUTER_BUC_GESTION_CLIENTES: string =
    RouterConstant.ROUTER_BUC + RouterConstant.ROUTER_GESTION_CLIENTES_BUC;
  public static readonly ROUTER_BUC_PAISES: string = "paises";
  public static readonly ROUTER_BUC_DATOS: string = "datos";
  public static readonly ROUTER_BUC_CLIENTES: string = "clientes";
  public static readonly ROUTER_BUC_LISTA: string = "lista";
  public static readonly ROUTER_BUC_NOTIFICATIONS: string = "notifications"; //notifications
  public static readonly ROUTER_BUC_TIPIFICACION: string = "tipificacion"; //notifications
  public static readonly ROUTER_ENROLAMIENTO_USUARIO: string =
    "enrolamiento-cliente";
  public static readonly ROUTER_CLAUSULAS: string = "clausulas";
  public static readonly ROUTER_BUC_TERMINO_ACEPTACION: string =
    "terminos-aceptacion";
  public static readonly ROUTER_CLIENTE_DATO: string = "clientes-dato";
  public static readonly ROUTER_QR: string = "qr";
  public static readonly ROUTER_FORMULARIO: string = "formularios";

  /** Router para la pagina de tope stp */
  public static readonly ROUTER_TOPE_SPT: string = "tope-spt";

  /** Router para los componentes de pines y recargas */
  public static readonly PINES_RECARGAS: string =
    "administracion-de-recargas-y-pines/";

  public static readonly GESTION_PROVEEDORES: string = "gestion-proveedores";
  public static readonly GESTION_OPERADORES: string = "gestion-operadores";
  public static readonly GESTION_PAQUETES: string = "gestion-paquetes";
  public static readonly GESTION_PINES: string = "gestion-pines";

  public static readonly ROUTER_PAGOS_PREMIOS: string = "pagos-premios";

  public static readonly ROUTER_METODOS_PAGO: string =
    "pasarela-pagos/gestion-metodos-pago";

  /** Router para la pagina de parametrizar region */
  public static readonly PARAMETRIZAR_REGION: string = "parametrizar-region";

  /** Router para la pagina de Confirmación de Colillas Anuladas */
  public static readonly CONFIRMACION_COLILLAS_ANULADAS: string =
    "confirmacion-colillas-anuladas";

  public static readonly ROUTER_PAGO_SERVICIOS_PUBLICOS: string =
    "pago-servicios-publicos";

  public static readonly NAVIGATE_CAJA_EGRESOS: string =
    "autenticado/administracion/recursos-caja/caja-egresos";

  public static readonly ROUTER_CONFIG_CARGUE_CONVENIOS: string =
    "servicios/pagos-recaudos/configuracion-cargue-convenios";

  public static readonly ROUTER_PARAMETRIZACION_CONVENIOS: string =
    "servicios/pagos-recaudos/parametrizacion-convenios";

  public static readonly ROUTER_CATEGORIA_CONVENIOS: string =
    "servicios/pagos-recaudos/categoria-convenios";

  public static readonly ROUTER_PARAM_PAGO_SERVICIOS_PUBLICOS: string =
    "param-pago-servicios-publicos";

  /** Router para el modulo SERVIVIOS */
  public static readonly ROUTER_SERVICIOS: string = "servicios";

  /** Router para el modulo GIROS NACIONALES */
  public static readonly ROUTER_GIROS_NACIONALES: string = "giros-nacionales/";

  public static readonly CARGAR_SOLICITUDES: string = "cargar-solicitudes";

  public static readonly APROBAR_SOLICITUDES: string = "aprobar-solicitudes";

  public static readonly REGISTRAR_NOVEDADES: string = "registrar-novedades";

  public static readonly AUTORIZAR_NOVEDADES: string = "autorizar-novedades";

  public static readonly ROUTER_CARGUE_VENTA_GENERACION_CARTERA: string =
    "cargue-venta-generacion-cartera";

    /** Rutas Sivical **/
  public static readonly ROUTER_MOD_SIVICAL: string = 'sivical/'
  public static readonly ROUTER_MOD_INTEGRACION: string = 'integracion'
  public static readonly ROUTER_MOD_REINTENTOS: string = 'reintentos'
  public static readonly ROUTER_MOD_HORARIO: string = 'horarios'
}
