import { AppDomainConstant } from 'src/app/constants/apis/app-domain.constant';

export class GestionUsuarioApiConstant {

    static readonly GESTION_USUARIOS: string = 'api/v1/users-management/';

    static readonly GESTION_PRIVILEGIOS: string = 'api/v1/privileges/';

    static readonly GESTION_BUC: string = 'api/v1/buc/federacion/';

    static readonly DISTRIBUIDORES: string = 'api/v1/multiempresa/distribuidores/';

    static readonly TIPO_DOCUMENTO: string = GestionUsuarioApiConstant.GESTION_USUARIOS + 'documents-type';
    static readonly ALL_DISTRIBUIDOR: string = GestionUsuarioApiConstant.DISTRIBUIDORES + 'all';

    static readonly USUARIO: string = GestionUsuarioApiConstant.GESTION_USUARIOS + 'users';

    static readonly PRIVILEGIOS: string = GestionUsuarioApiConstant.GESTION_PRIVILEGIOS + 'roles';

    static readonly BUC: string = GestionUsuarioApiConstant.GESTION_BUC + 'client';

    static readonly URL_TIPO_DOCUMENTO: string =
         AppDomainConstant.URI_GATEWAY +
        GestionUsuarioApiConstant.TIPO_DOCUMENTO;
        
    static readonly URL_DISTRIBUIDOR: string =
         AppDomainConstant.URI_GATEWAY +
        GestionUsuarioApiConstant.ALL_DISTRIBUIDOR;

    static readonly URL_USUARIO: string =
         AppDomainConstant.URI_GATEWAY +
        //'https://dev-administracion.konexinnovation.com/' +
        GestionUsuarioApiConstant.USUARIO;

    static readonly URL_PRIVILEGIOS: string =
         AppDomainConstant.URI_GATEWAY +
        //'https://dev-administracion.konexinnovation.com/' +
        GestionUsuarioApiConstant.PRIVILEGIOS;

    static readonly URL_BUC: string =
        AppDomainConstant.URI_GATEWAY +
        GestionUsuarioApiConstant.BUC;
}