import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsConverterService {

  constructor() { }

  /**
   * Metodo que recibe un DTO y genera los queryParams para utilizar en los params de un cliente http
   * si algun dato del DTO es null el query param no se va a setear y llegaría al backend como null por defecto
   * el dto solo puede obtener un objeto como maximo ya que no soporta una jerarquia de más de dos
   */
  public convertDtoToHttpParams(dto: any): HttpParams {
   
    let httpParams = new HttpParams();
    Object.keys(dto).forEach(function (key) {
      if(dto[key] !== null){
        if(typeof dto[key]  == 'object' && Object.keys(dto[key]).length>0 ){
          Object.keys(dto[key]).forEach(function (subKey) {
            if(dto[key][subKey]!=null){
              httpParams = httpParams.append(`${key}.${subKey}`, dto[key][subKey]);    
            }
          });
        }
        else{
          if(dto[key]!=null){
            httpParams = httpParams.append(key, dto[key]);
          }
        }
      }
    });
    return httpParams
  }
}
