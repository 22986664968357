import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { BiometricoConstant } from 'src/app/constants/apis/biometrico/biometrico.constant';
import { AutenticacionAPIConstant } from 'src/app/constants/apis/seguridad/autenticacion-api.constant';
import { BiometricoResponseDTO } from 'src/app/dtos/biometrico/biometrico-response.dto';
import { AutenticacionRequestDTO } from 'src/app/dtos/seguridad/autenticacion/autenticacion-request.dto';
import { AutenticacionResponseDTO } from 'src/app/dtos/seguridad/autenticacion/autenticacion-response.dto';
import { LoginMfaRequestDTO } from 'src/app/dtos/seguridad/autenticacion/login-mfa-request.dto';
import { LoginMfaResponseDTO } from 'src/app/dtos/seguridad/autenticacion/login-mfa-response.dto';
import { LoginRequestDTO } from 'src/app/dtos/seguridad/autenticacion/login-request.dto';
import { LoginSingOutRequestDTO } from 'src/app/dtos/seguridad/autenticacion/login-singioutrequest.dto';
import { PrivilegesRequestDTO } from 'src/app/dtos/seguridad/autenticacion/privileges-request.dto';
import { PrivilegesResponseDTO } from 'src/app/dtos/seguridad/autenticacion/privileges-response.dto';
import { BienvenidaRequestDTO } from 'src/app/dtos/seguridad/bienvenida/bienvenida-request.dto';
import { BienvenidaResponseDTO } from 'src/app/dtos/seguridad/bienvenida/bienvenida-response.dto';
import { QueryParamsConverterService } from '../shared/services/query-params-converter.service';
import { ConfiguracionUsuarioAPIConstant } from './../../constants/apis/seguridad/configuracion-usuario-api.constant';
import { UsuariosDTO } from './../../dtos/configuracion-usuario/usuarios.dto';
import { MessageResponseDTO } from './../../dtos/transversal/message-response.dto';
import { CajaMacFinancieraDTO } from 'src/app/dtos/seguridad/autenticacion/caja-finanaciera.dto';
import { RefreshPasswordDTO } from 'src/app/dtos/seguridad/autenticacion/refresh-password.dto';
import { GestionUsuarioApiConstant } from 'src/app/constants/apis/administracion/federacion/gestion-usuario.api.constant';
import { AssociateSoftwareRequestDTO } from 'src/app/dtos/seguridad/autenticacion/associate-software-request.dto';
import { AssociateSoftwareResponseDTO } from 'src/app/dtos/seguridad/autenticacion/associate-software-response.dto';
import { VerifyeSoftwareRequestDTO } from 'src/app/dtos/seguridad/autenticacion/verify-software-request.dto';
import { VerifySoftwareResponseDTO } from 'src/app/dtos/seguridad/autenticacion/verify-software-response.dto';

/**
 * Clase que contiene los procesos de negocio para la autenticacion en el sistema
 */
@Injectable()
export class SeguridadService {

  /**
   * @param HTTP para hacer las peticiones a los servicios REST
   */
  constructor(private http: HttpClient, private queryParamsConverterService: QueryParamsConverterService) {}

  /**
   * Servicio que permite soportar el proceso de iniciar sesion
   *
   * @param credenciales, contiene las credenciales del USER
   * @return DTO con los datos inciales
   */
  public iniciarSesion(credenciales: AutenticacionRequestDTO): Observable<AutenticacionResponseDTO> {
    return this.http.post<AutenticacionResponseDTO>(
      AutenticacionAPIConstant.URL_LOGIN,
      credenciales
    );
  }

  public login(credenciales: LoginRequestDTO): Observable<AutenticacionResponseDTO> { 
    return this.http.post<AutenticacionResponseDTO>(
      AutenticacionAPIConstant.URL_LOGIN,
      credenciales
    );
  }

  /**
   * Servicio para obtener los datos necesarios de bienvenida de la app
   * cuando la autenticacion es OK
   *
   * @param data, parametros necesarios para obtener los datos de bienvenida
   * @return DTO con los datos configurados para la bienvenida de la app
   */
  public getDatosBienvenida(data: BienvenidaRequestDTO): Observable<BienvenidaResponseDTO> {
    return this.http.post<BienvenidaResponseDTO>(
      AutenticacionAPIConstant.URL_BIENVENIDA,
      data
    );
  }

  /**
   * metodo encargado de actualizar la contraseña de un usuario
   * @param usuarioDTO 
   * @returns 
   */
  public actualizarClaveIngreso(usuarioDTO: UsuariosDTO): Observable<MessageResponseDTO> {
    return this.http.put<MessageResponseDTO>(
      `${ConfiguracionUsuarioAPIConstant.URL_ACTUALIZAR_CLAVE_INGRESO}`,
      usuarioDTO
    );
  }

  /**
   * metodo encargado de actualizar el nombre login de usuario
   * @param usuarioDTO 
   * @returns 
   */
  public actualizarNombreUsuario(usuarioDTO: UsuariosDTO): Observable<MessageResponseDTO> {
    return this.http.put<MessageResponseDTO>(
      `${ConfiguracionUsuarioAPIConstant.URL_ACTUALIZAR_NOMBRE_USUARIO}`,
      usuarioDTO
    );
  }

  /**
   * metodo encargado de enviar y petición de huella y revivir datos del huellero
  */
  public getDatosEnrolamiento(): Observable<BiometricoResponseDTO> {
    return this.http.get<BiometricoResponseDTO>(`${BiometricoConstant.URL_EMROLLMENT_HUELLA}`);
  }

  /**
   * metodo encargado de enviar y petición de huella y revivir datos del huellero
   */
  public getDatosBiometrico(): Observable<BiometricoResponseDTO> {
    return this.http.get<BiometricoResponseDTO>(`${BiometricoConstant.URL_LECTURA_BIOMETRICO}`);
  }

  public getOS(): Observable<BiometricoResponseDTO> {
    return this.http.get<BiometricoResponseDTO>(`${BiometricoConstant.URL_LECTURA_OS}`);
  }

  public getMac(): Observable<BiometricoResponseDTO> {
    return this.http.get<BiometricoResponseDTO>(`${BiometricoConstant.URL_LECTURA_MAC}`);
  }
  
  public loginMfa(dataMfa: LoginMfaRequestDTO): Observable<LoginMfaResponseDTO> {
    return this.http.post<LoginMfaResponseDTO>(
      AutenticacionAPIConstant.URL_LOGIN_MFA,
      dataMfa
    );
  }

  public associateSoftwareToken(data: AssociateSoftwareRequestDTO): Observable<string> {
    return this.http.post(
      AutenticacionAPIConstant.URL_ASSOCIATE_SOFTWARE,
      data, 
      {responseType: 'text'}
    );
  }

  public verifySoftwareToken(data: VerifyeSoftwareRequestDTO): Observable<AutenticacionResponseDTO> {
    return this.http.post<AutenticacionResponseDTO>(
      AutenticacionAPIConstant.URL_VERIFY_SOFTWARE,
      data
    );
  }

  public recuperarContrasena(usuarioDTO: any): Observable<any> {
    return this.http.patch<any>(`${ConfiguracionUsuarioAPIConstant.URL_REESTABLECER_CONTRASENA}`, usuarioDTO);
  }

  public cambiarContrasena(usuarioDTO: any): Observable<any> {
    return this.http.patch<any>(`${ConfiguracionUsuarioAPIConstant.URL_CAMBIAR_CONTRASENA}`, usuarioDTO);
  }

  public renovarContrasena(usuarioDTO: any): Observable<any> {
    return this.http.patch<any>(`${ConfiguracionUsuarioAPIConstant.URL_RENOVAR_CONTRASENA}`, usuarioDTO);
  }
  
  public getPrivileges(filtro: PrivilegesRequestDTO, idToken: string): Observable<PrivilegesResponseDTO> {
    const authToken = idToken;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`
    });
    let data = this.queryParamsConverterService.convertDtoToHttpParams(filtro);
    return this.http.get<PrivilegesResponseDTO>(AutenticacionAPIConstant.URL_PRIVILEGES_ROLES, {headers, params: data});
  }
  public getTerminal(deviceInfo: any): Observable<any> {
    return this.http.get<any>(`${AutenticacionAPIConstant.URL_GET_PUNTO_VENTA}`, { params: deviceInfo });
  }
  public getCaja(deviceInfo: any): Observable<CajaMacFinancieraDTO> {
    return this.http.get<CajaMacFinancieraDTO>(`${AutenticacionAPIConstant.URL_GET_CAJA}`, { params: deviceInfo });
  }
  public signout(body: LoginSingOutRequestDTO): Observable<boolean> {
    return this.http.post(AutenticacionAPIConstant.URL_SIGNOUT, body)
      .pipe(
        map(() => {
          return true; 
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  public refreshPassword(changePasswordRequest: RefreshPasswordDTO): Observable<any> {
    return this.http.patch<any>(
      AutenticacionAPIConstant.URL_ACTUALIZAR_CONTRASENA_USUARIO,
      changePasswordRequest
    );
  }
  public getUsuarioByFilter(filter: any, idToken: string): Observable<any> { 
    const authToken = idToken;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`
    });
    return this.http.get<any>(`${GestionUsuarioApiConstant.URL_USUARIO}`, {headers, params: filter});
  }
  public refreshToken(filter: any): Observable<any> {
    return this.http.post<any>(`${AutenticacionAPIConstant.URL_REFRESH_TOKEN}`, filter);
  }  

  public imprimirPDF(pdfFile: string, resourceName: string) {
		const body = {
			file: pdfFile,
			printerName: resourceName
		}
		return this.http.post<any>(BiometricoConstant.URL_IMPRIMIR, body);
	}
}