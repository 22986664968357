import { AppDomainConstant } from "../app-domain.constant";

export class ParametrizacionesAPIConstant {

    static readonly BASE_URL: string = 'api/v1/parametrizaciones/';
    static readonly TABLA_PARAMETRICA: string = 'tabla-parametrica';
    static readonly BASE_URL_RESOURCES: string = "api/v1/resource-management/"
    static readonly CATEGORIA_PRODUCTO: string = "categoria-producto"

    static readonly URL_GET_PARAMETRIZACIONES: string =
        AppDomainConstant.URI_GATEWAY +
        this.BASE_URL +
        this.TABLA_PARAMETRICA;

    static readonly URL_POST_PARAMETRIZACIONES: string =
        AppDomainConstant.URI_GATEWAY +
        this.BASE_URL +
        this.TABLA_PARAMETRICA;

    static readonly URL_TABLA_PARAMETRICA: string =
        AppDomainConstant.URI_GATEWAY +
        this.BASE_URL;

    static readonly URL_LOAD_RESOURCE: string =
        AppDomainConstant.URI_GATEWAY +
        this.BASE_URL_RESOURCES + 'firma-digital';

    static readonly URL_GET_CATEGORIAS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL +
    this.CATEGORIA_PRODUCTO;




}