import { Component, HostListener } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { translations } from './configs/primeapi.config';
import { environment } from 'src/environments/environment';

/**
 * Componente de inicio de la aplicacion Admin
 */
@Component({
  selector: 'app-root',
  template: '<app-spinner></app-spinner> <router-outlet></router-outlet>'
})
export class AppComponent {
  devToolsOpen = false;
  previousInnerHeight = window.innerHeight;
  previousInnerWidth = window.innerWidth;

  constructor(private primeNG: PrimeNGConfig) {
    this.detectDevTools();
    this.primeNG.setTranslation(translations);
  }

  @HostListener('document:keydown', ['$event'])
  handlerKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'F12' ||
      (event.ctrlKey && event.shiftKey && event.key === 'I' && environment.production) ||
      (event.ctrlKey && event.shiftKey && event.key === 'J' && environment.production)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (environment.production) {
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;

      if (
        this.previousInnerHeight !== innerHeight ||
        this.previousInnerWidth !== innerWidth
      ) {
        this.devToolsOpen = true;
        this.detectDevTools();
      }

      this.previousInnerHeight = innerHeight;
      this.previousInnerWidth = innerWidth;
    }
  }

  detectDevTools() {
    if (environment.production) {
      const threshold = 160;
      if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
        window.location.href = 'about:blank'
      }

      setInterval(() => {
        if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
          window.location.href = 'about:blank'
        }
      }, 1000)
    }
  }
}
