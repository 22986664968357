import { AppDomainConstant } from "../../../app-domain.constant";

export class AprobacionConfiguracionImpresionAPIConstant {

    static readonly BASE_CONFIGURACION_IMPRESION: string = 'api/v1/configuracion-impresion/';   
    static readonly BASE_COMPROBANTE_VENTA: string = 'api/v1/ventas/comprobante/';
    

    static readonly URL_GET_CONFIGURACIONES_IMPRESION: string = 
    AppDomainConstant.URI_GATEWAY + 
    this.BASE_CONFIGURACION_IMPRESION +
    'configuracion-impresion/get-list-approval-by-status';

    static readonly URL_SAVE_DETALLE_PRUEBA: string = 
    AppDomainConstant.URI_GATEWAY + 
    this.BASE_CONFIGURACION_IMPRESION +
    'detalle-prueba';

    static readonly URL_SAVE_OBSERVACION: string = 
    AppDomainConstant.URI_GATEWAY + 
    this.BASE_CONFIGURACION_IMPRESION +
    'observacion';

    static readonly URL_GET_COMPROBANTE_FISICO: string = 
    AppDomainConstant.URI_GATEWAY + 
    this.BASE_COMPROBANTE_VENTA +
    'generar-comprobante-fisico';


}