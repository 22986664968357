import { AppDomainConstant } from "../app-domain.constant";

export class BiometricoConstant {
    /**PATH que permite Obtener información del biómetrico */
    static readonly URL_LECTURA_BIOMETRICO: string = 
    AppDomainConstant.URI_BIOMETRIC + '8093/api/konex/fingerprint';

    static readonly URL_LECTURA_OS: string = 
    AppDomainConstant.URI_BIOMETRIC + '8093/api/konex/getSO';
    
    static readonly URL_LECTURA_MAC: string = 
    AppDomainConstant.URI_BIOMETRIC + '8093/api/konex/mac';

    static readonly URL_EMROLLMENT_HUELLA: string =
    AppDomainConstant.URI_BIOMETRIC + '8093/api/konex/enrollment';

    static readonly URL_IMPRIMIR: string =
    AppDomainConstant.URI_BIOMETRIC + '8093/api/konex/printAvailableFractions';

}