import { AppDomainConstant } from '../../../app-domain.constant';

export class NovedadesAPIConstant {
  static readonly NOVELTY: string = 'api/v1/transfer/novelty';

  static readonly OBSERVATION: string = 'api/v1/transfer/novelty-observation';

  static readonly BASE_URL_RESOURCES: string = 'api/v1/resource-management/';

  static readonly FOLDER_NOVEDADES = 'giros/nacionales/novedades';

  static readonly BASE_ADMINISTRAR_VENDEDORES: string =
    'api/v1/administrar-vendedores/datos-personales/';

  static readonly URL_LOAD_RESOURCE: string =
    AppDomainConstant.URI_GATEWAY + this.BASE_URL_RESOURCES + 'file';

  static readonly URL_NOVELTY: string =
    AppDomainConstant.URI_GATEWAY + this.NOVELTY;

  static readonly URL_AUTHORIZATION: string =
    AppDomainConstant.URI_GATEWAY + this.NOVELTY + '/update-state';

  static readonly URL_NOVELTY_OBSERVATION: string =
    AppDomainConstant.URI_GATEWAY + this.OBSERVATION;

  static readonly URL_GET_ADMINISTRACION_VENDEDOR: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_ADMINISTRAR_VENDEDORES +
    'filtrar';
}
