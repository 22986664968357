import { AppDomainConstant } from '../app-domain.constant';

/**
 * Clase que contiene todas las constantes de la API para el modulo de cargue de archivos
 */
export class CargueAPIConstant {

  /** Nombre del REST para el modulo CARGUE */
  static readonly CARGUE_API: string = 'cargue/';

  /** URL del recurso que permite consultar los errores para el cargue de loterias virtual */
  static readonly URL_GET_ERRORES_LOTERIA_VIRTUAL: string =
    AppDomainConstant.URI_GATEWAY +
    CargueAPIConstant.CARGUE_API +
    'getErroresLoteriaVirtual';

  /** URL del recurso para el cargue de archivo de loterias virtual */
  static readonly URL_CARGUE_ARCHIVO_LOTERIA_VIRTUAL: string =
    AppDomainConstant.URI_GATEWAY +
    CargueAPIConstant.CARGUE_API +
    'cargueLoteriaVirtual';
}
