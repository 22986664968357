import { AppDomainConstant } from '../../../app-domain.constant';

export class SolicitudesAPIConstant {
  static readonly SOLICITUDE: string = 'api/v1/transfer/solicitude';

  static readonly OBSERVATION: string = 'api/v1/transfer/observation';

  static readonly BASE_URL_RESOURCES: string = 'api/v1/resource-management/';

  static readonly FOLDER_SOLICITUDE = 'giros/nacionales/solicitudes';

  static readonly BASE_URL_NOTIFICATIONS = 'api/v1/notificaciones/email/send';

  static readonly URL_LOAD_RESOURCE: string =
    AppDomainConstant.URI_GATEWAY + this.BASE_URL_RESOURCES + 'file';

  static readonly URL_SEND_NOTIFICATION: string =
    AppDomainConstant.URI_GATEWAY + this.BASE_URL_NOTIFICATIONS;

  static readonly URL_SOLICITUDE: string =
  AppDomainConstant.URI_GATEWAY + this.SOLICITUDE;

  static readonly URL_AUTHORIZATION: string =
    AppDomainConstant.URI_GATEWAY + this.SOLICITUDE + '/update-state';

  static readonly URL_SOLICITUDE_OBSERVATION: string =
    AppDomainConstant.URI_GATEWAY + this.OBSERVATION;
}
