import { AppDomainConstant } from '../app-domain.constant';

/**
 * Clase que contiene todas las constantes de la API para el modulo de escrutinio
 */
export class EscrutinioAPIConstant {

  /** Nombre del REST para el modulo Loterias */
  static readonly LOTERIAS: string = 'escrutinio/loterias/';

  /** Nombre del REST para el modulo sorteos */
  static readonly SORTEOS: string = 'escrutinio/sorteos/';

  /** Nombre del REST para los procesos de calendario sorteos */
  static readonly CALENDARIO_SORTEOS: string = 'escrutinio/calendarioSorteo/';

  /** Nombre del REST para los procesos de calendario sorteos */
  static readonly ESCRUTAR: string = 'escrutinio/premios/';

  static readonly MODALIDADES_API: string = 'apuestas/modalidades/';

  /** Nombre del REST que contiene los procesos de negocio para numeros excluidos*/
  static readonly NUMERO_EXCLUIDO: string = 'escrutinio/numeroExcluido/';

  /** Nombre del REST que contiene los procesos de negocio para parametro de premios*/
  static readonly PARAMETRO_PREMIOS: string = 'escrutinio/parametroPremios/';

  static readonly BASE_URL_ESCRUTINIO: string = "api/v1/escrutinio-loteria-sorteo/";

  static readonly BASE_URL_RESOURCE_S3: string = "api/v1/resource-management";

  static readonly ASIGNAR_FECHAS_DETALLES: string = "asignar-fechas-detalles/"

  static readonly URL_CONSULTAR_LOTERIAS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'loterias';

  static readonly URL_CONSULTAR_LOTERIAS_GANADORAS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'consultarLoteriasGanadoras';


  static readonly URL_CREAR_LOTERIA: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'crearLoteria';

  static readonly URL_EDITAR_LOTERIA: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'editarLoteria';

  static readonly URL_CONSULTAR_NUMEROS_GANADORES: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.SORTEOS +
    'consultarSorteos';

  static readonly URL_CREAR_NUMERO_GANADOR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.SORTEOS +
    'crearSorteoDetalle';

  /** URL del recurso para obtener las programaciones de los sorteos paginados */
  static readonly URL_CONSULTAR_CALENDARIO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'consultarCalendario';

  /** URL del recurso para la creacion de las programaciones de los sorteos */
  static readonly URL_CREAR_CALENDARIO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'crearCalendario';

  /** URL del recurso para la cancelacion de toda la serie de un calendario sorteos */
  static readonly URL_CANCELAR_TODA_LA_SERIE: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'cancelarTodaLaSerie';

  /** URL del recurso para la cancelacion de solo un sorteo del calendario */
  static readonly URL_CANCELAR_SOLO_SORTEO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'cancelarSoloSorteo';

  /** URL del recurso para la edicion de toda la serie de un calendario sorteos */
  static readonly URL_EDITAR_TODA_LA_SERIE: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'editarTodaLaSerie';

  /** URL del recurso para la edicion de solo un sorteo del calendario */
  static readonly URL_EDITAR_SOLO_SORTEO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'editarSoloSorteo';

  /** URL del recurso para validar si el sorteo a editar tiene autorizaciones pendiente */
  static readonly URL_HAY_AUTORIZACIONES_PENDIENTES_SORTEO_EDITAR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'hayAutorizacionesPendientesSorteoEditar';

  /** URL del recurso para validar si la serie a editar tiene autorizaciones pendiente */
  static readonly URL_HAY_AUTORIZACIONES_PENDIENTES_SERIE_EDITAR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'hayAutorizacionesPendientesSerieEditar';

  /** URL del recurso que permite consultar los dias de la serie a editar */
  static readonly URL_GET_DIAS_SERIE_EDITAR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'getDiasSerieEditar?idSerie=';

  /** URL del recurso para obtener las loterias */
  static readonly URL_CONSULTAR_SIGNOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.MODALIDADES_API +
    'consultarSignos';

  /** URL del recurso para obtener las loterias */
  static readonly URL_CONSULTAR_RUTA_IMAGENES: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'consultarRutaServidor';

  static readonly URL_CONSULTAR_NOMBRES_IMAGENES: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.LOTERIAS +
    'consultarNombresImagenes';

  /** Se utiliza para validar si una loteria tiene sorteos */
  static readonly URL_TIENE_SORTEOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'validarSiTieneSorteos';

  /** Se utiliza para validar si una loteria tiene sorteos */
  static readonly URL_GET_ESCRUTAR: string =
    AppDomainConstant.URI_GATEWAY+
    EscrutinioAPIConstant.ESCRUTAR +
    'escrutar';

  static readonly URL_GET_CONSULTAR_ESCRUTAR: string =
    AppDomainConstant.URI_GATEWAY+
    EscrutinioAPIConstant.ESCRUTAR +
    'escrutar-consultar';

  static readonly URL_GET_REVERSAR_ESCRUTAR: string =
    AppDomainConstant.URI_GATEWAY+
    EscrutinioAPIConstant.ESCRUTAR +
    'reversarEscrutar';

  static readonly URL_GET_CONFRIMAR_ESCRUTAR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.ESCRUTAR +
    'confrimarEscrutar';

  /** URL del recurso que permite consultar sorteos en venta */
  static readonly URL_SORTEOS_VENTA: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.CALENDARIO_SORTEOS +
    'obtenerVentasSorteo';

  /** Permite obtener los detalles del premio */
  static readonly URL_GET_DETALLE_PREMIO: string =
    AppDomainConstant.URI_GATEWAY+
    EscrutinioAPIConstant.ESCRUTAR +
    'getDetallePremio';

  /** URL para crear un numero a excluir */
  static readonly URL_CREAR_NUMERO_EXCLUIR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'crearNumeroExcluir';

  /** URL para consultar numeros excluidos */
  static readonly URL_GET_NUMEROS_EXCLUIDOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'getNumerosExcluidos';

  /** URL para consultar los productos del numero excluido*/
  static readonly URL_GET_PRODUCTOS_NUMERO_EXCLUIDO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'getProductosNumeroExcluido';

  /** URL para actualizar los productos del numero excluido*/
  static readonly URL_UPDATE_NUMERO_EXCLUIDO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'updateNumeroExcluido';

  /** URL para eliminar el numero excluido*/
  static readonly URL_DELETE_NUMERO_EXCLUIDO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'deleteNumeroExcluido';

  /** URL para crear mensaje de los numeros a excluir para una empresa */
  static readonly URL_CREAR_MENSAJE_NUMERO_EXCLUIR: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'crearMensajeNumeroExcluir';

  /** URL para consultar el mensaje de los numeros excluidos en una empresa */
  static readonly URL_GET_MENSAJE_NUMEROS_EXCLUIDOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.NUMERO_EXCLUIDO +
    'getMensajeNumeroExcluido';

  /** URL para consultar la parametrizacion en parametro de premios */
  static readonly URL_GET_PARAMETROS_PREMIOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.PARAMETRO_PREMIOS +
    'getParametrosPremios';

  /** URL para actualizar la parametrizacion en parametro de premios */
  static readonly URL_UPDATE_PARAMETROS_PREMIOS: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.PARAMETRO_PREMIOS +
    'updateParametrosPremios';

  /** URL para consultar el historico de parametro premios */
  static readonly URL_HISTORICO_PARAMETRO_PREMIO: string =
    AppDomainConstant.URI_GATEWAY +
    EscrutinioAPIConstant.PARAMETRO_PREMIOS +
    'getHistoricoParametroPremio';

  static readonly URL_GET_LOTERIAS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    "loteria-criteria";

  static readonly URL_GET_ASIGNAR_FECHA_DETALLE_BY_FECHA_NUMBRE_LOTERIAID: string =
    AppDomainConstant.URI_GATEWAY +
      this.BASE_URL_ESCRUTINIO +
      this.ASIGNAR_FECHAS_DETALLES;

  static readonly URL_GET_LISTA_IMAGENES_LOTERIAS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'loterias-images';

  static readonly URL_BASE_LOTERIA: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'loteria';

  static readonly URL_ULTIMO_SORTEO_JUGADO: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'asignar-fecha-jugado';

  static readonly URL_GET_LOTERIAS_BY_EMPRESA: string =
  AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'loterias';

  static readonly URL_GET_FECHAS_LOTERIA_CRITERIA: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'asignar-fecha-criteria';

  static readonly URL_GET_ULTIMO_SORTEO_LOTERIA: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    this.ASIGNAR_FECHAS_DETALLES +
    "ultimo-sorteo";

  static readonly URL_POST_CREATE_FECHAS_SORTEOS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'asignar-fecha';

  static readonly URL_GET_FECHAS_SORTEOS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'asignar-fecha';

  static readonly URL_PUT_CAMBIO_ESTADO_SORTEOS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO;

  static readonly URL_PUT_EDICION_FECHA_SORTEO_UNICO: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'item-serie-asignar-fecha'

  static readonly URL_PUT_EDICION_TOTAL_SERIE: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_ESCRUTINIO +
    'toda-serie-asignar-fecha'

    static readonly URL_IMAGENES: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_RESOURCE_S3 +
    '/images';

    static readonly URL_SAVE_IMAGENES: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_RESOURCE_S3 +
    '/images';
}