import { AppDomainConstant } from "../../app-domain.constant";


export class ConfiguracionContableProductosApiConstant {

    static readonly BASE_CONFIGURACION_CONTABLE: string = 'api/v1/control-operacion-producto-contables/';

    static readonly BASE_PARAMETRIZACION: string = 'api/v1/parametrizaciones/';

    static readonly BASE_GESTION_CONTRATOS: string = 'api/v1/gestion-contratos/';

    static readonly BASE_PARAMETRIZACION_PRODUCTOS: string = 'api/v1/control-operacion-parametrizacion-producto/';

    static readonly URL_CATEGORIA: string = 'categoria-producto';

    static readonly BASE_URL_RESOURCES: string = "api/v1/resource-management/"


    static readonly URL_GET_LISTADO_CONFIG_CONTABLE: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'configuracion-producto';

    static readonly URL_GET_CONFIG_PRODUCTO: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'configuracion-producto/find-by-id';

    static readonly URL_SAVE_UPDATE_CONFIG_CONTABLE: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'configuracion-contable-productos';

    static readonly URL_SAVE_UPDATE_COMISION_TABLA_DIFERENCIAL: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'comision-tabla-diferencial'

    static readonly URL_DELETE_COMISION_TABLA_DIFERENCIAL: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'comision-tabla-diferencial'

    static readonly URL_SAVE_UPDATE_CONFIG_PRODUCTO: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'configuracion-producto';

    static readonly URL_GET_CATEGORIAS: string =
    AppDomainConstant.URI_GATEWAY + 
    this.BASE_PARAMETRIZACION_PRODUCTOS +
    this.URL_CATEGORIA;

    static readonly URL_GET_PRODUCTOS: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_PARAMETRIZACION_PRODUCTOS + 
    'producto'

    static readonly URL_GET_SUBPRODUCTOS: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_PARAMETRIZACION_PRODUCTOS + 
    'producto/sub-producto'

    static readonly URL_GET_TIPOS_CONTRATO: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_GESTION_CONTRATOS +
    'contrato-aliado'


    static readonly URL_GET_PARAMETRO: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_PARAMETRIZACION +
    'parametro-contable'

    static readonly URL_GET_CATEGORIZACION_CONVENIOS: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_PARAMETRIZACION +
    'categoria-convenios'

    static readonly URL_LOAD_RESOURCE: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_URL_RESOURCES + 'file';

    static readonly URL_SAVE_UPDATE_DOCUMENTO_PLAN_PREMIO: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'documento-pago-premio'

    static readonly URL_SAVE_UPDATE_RANGO_PAGO_PREMIO: string = 
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'rango-pago-premio'

    static readonly URL_DELETE_DOCUMENTO_PAGO_PREMIO: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'documento-pago-premio'
    
    static readonly URL_DELETE_RANGO_PAGO_PREMIO: string =
    AppDomainConstant.URI_GATEWAY +
    this.BASE_CONFIGURACION_CONTABLE +
    'rango-pago-premio'

}