export const environment = {
  production: false,
  mseServerApiUrl: 'https://smbhe0rcl4.execute-api.us-east-1.amazonaws.com/dev/',
  serverApiUrl: 'https://2lsqi33ab1.execute-api.us-east-1.amazonaws.com/dev/',
  jsaServerApiUrl: 'https://sw6wisxpcj.execute-api.us-east-1.amazonaws.com/dev/',
  bucketS3Image: 'https://s3.amazonaws.com/img.logiiblue/img/',
  localhostServerUrl: 'http://localhost:',
  recaptcha: {
    siteKey: '6LeO-1InAAAAAGT0mibA2dVjmeom4aqJgOYYl3GD',
  },
  serverApiUrlBuc: 'https://pagatodobucservice.dcsas.com.co:8113/buc/',
  //serverApiUrlBuc: 'https://dev.dcsas.com.co:8113/buc/',
  serverMicroServiceSivical: 'https://qa-administracion.dcsas.co/',
  serverMicroService: 'https://qa-administracion.dcsas.com.co/'
};