import { Injectable } from '@angular/core';
import { TipoEventoConstant } from '../constants/tipo-evento.constant';
import { AutenticacionResponseDTO } from '../dtos/seguridad/autenticacion/autenticacion-response.dto';
import { SessionStoreUtil } from 'src/app/utilities/session-store.util';
import { RouterConstant } from '../constants/router.constant';
import { MenuItemDTO } from '../dtos/seguridad/menu/menu-item.dto';
import { MenuItem } from 'primeng/api/menuitem';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';

/**
 * Se utiliza para los routers secundarios, donde se requiere
 * que el usuario tenga privilegios para acceder a estos modulos
 * del negocio.
 */
@Injectable()
export class PrivilegiosGuard implements CanActivate {

  /**
   * @param router, se utiliza para el redireccionamiento si
   * surge algun error en el filtro
   */
  constructor(private router: Router) {}

  /**
   * Metodo que permite validar si el usuario tiene privilegios
   * para acceder al modulo que pretende ingresar
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // indica si el usuario tiene privilegios
    let tienePrivilegios = true;

    // se obtiene la URL destino
    const url = state.url;

    // se obtiene los datos de la autenticacion del session store
    const auth: AutenticacionResponseDTO = SessionStoreUtil.auth(TipoEventoConstant.GET);

    // se verifica si el usuario esta autenticado
    if (auth && auth.usuario && auth.usuario.idUsuario) {

      // se verifica si el usuario tiene modulos asignados
      const modulos: Array<MenuItemDTO> = SessionStoreUtil.menu(TipoEventoConstant.GET);
      if (modulos && modulos.length) {
        tienePrivilegios = this.tieneUserPrivilegios(modulos, url);
      } else {
        tienePrivilegios = this.goToDeniedPage();
      }
    } else {
      tienePrivilegios = this.goToDeniedPage();
    }
    return tienePrivilegios;
  }

  /**
   * Metodo que permite validar si el usuario tiene privilegios para la URL destino
   */
  private tieneUserPrivilegios(modulos: Array<MenuItemDTO>, url: string): boolean {
    const itemFound = this.findItemRecursive(modulos as MenuItemDTO[], url);
    if (itemFound && itemFound.id) {
      return true;
    }
    return this.goToDeniedPage();
  }

  /**
   * Metodo recursivo que permite buscar el item de los modulos
   * asignados al usuario que coincida con la url destino
   */
  private findItemRecursive(items: MenuItem[], url: string): MenuItem | null {
    for (const item of items) {
      if (item.routerLink === url) {
        return item;
      } else if (item.items?.length) {
        const itemFound = this.findItemRecursive(item.items, url);
        if (itemFound) {
          return itemFound;
        }
      }
    }
    return null;
  }

  /**
   * Metodo que permite ir a un router especifico cuando surge
   * algun error en la seguridad del router
   */
  private goToDeniedPage(): boolean {
    this.router.navigate([RouterConstant.NAVIGATE_DENEGADO]);
    return false;
  }
}
