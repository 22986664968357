import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { translations } from './configs/primeapi.config';

/**
 * Componente de inicio de la aplicacion Admin
 */
@Component({
  selector: 'app-root',
  template: '<app-spinner></app-spinner> <router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(private primeNG: PrimeNGConfig) {
    this.primeNG.setTranslation(translations);
  }
}
