import { environment } from '../../../environments/environment';

export class AppDomainConstant {

  static readonly URI_GATEWAY: string = environment.serverApiUrl;

  static readonly URI_MICROSERVICE : string = environment.serverMicroService;

  static readonly URI_BUCKETS3IMAGE: string = environment.bucketS3Image;

  static readonly URI_BIOMETRIC: string = environment.localhostServerUrl;

  static readonly  URI_GATEWAY_ADMINISTRATOR_QA: string = environment.serverMicroServiceSivical;


}
