import { AppDomainConstant } from '../app-domain.constant';

export class ConfiguracionUsuarioAPIConstant {

  static readonly CONFIGURACION_CUENTA_USUARIO: string = 'seguridad/configuracionUsuario/';
  static readonly GESTION_REESTABLECER_CONTRASENA: string = 'api/v1/users-management/';
  static readonly GESTION_CAMBIAR_CONTRASENA: string = 'api/v1/users-management/';
  static readonly REESTABLECER_CONTRASENA: string = ConfiguracionUsuarioAPIConstant.GESTION_REESTABLECER_CONTRASENA + 'credentials/forget-password';
  static readonly CAMBIAR_CONTRASENA: string = ConfiguracionUsuarioAPIConstant.GESTION_CAMBIAR_CONTRASENA + 'credentials/change-password';
  static readonly RENOVAR_CONTRASENA: string = ConfiguracionUsuarioAPIConstant.GESTION_CAMBIAR_CONTRASENA + 'credentials/new-password';
  static readonly RESET_SOFTWARE_TOKEN: string = ConfiguracionUsuarioAPIConstant.GESTION_CAMBIAR_CONTRASENA + 'credentials/reset-software-token';

    static readonly URL_ACTUALIZAR_CLAVE_INGRESO: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.CONFIGURACION_CUENTA_USUARIO +
    'actualizarClaveIngreso';

    static readonly URL_ACTUALIZAR_NOMBRE_USUARIO: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.CONFIGURACION_CUENTA_USUARIO +
    'actualizarNombreUsuario';

    static readonly URL_REESTABLECER_CONTRASENA: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.REESTABLECER_CONTRASENA;

    static readonly URL_CAMBIAR_CONTRASENA: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.CAMBIAR_CONTRASENA;

    static readonly URL_RENOVAR_CONTRASENA: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.RENOVAR_CONTRASENA;

    static readonly URL_RESET_SOFTWARE_TOKEN: string =
    AppDomainConstant.URI_GATEWAY +
    ConfiguracionUsuarioAPIConstant.RESET_SOFTWARE_TOKEN;
}
