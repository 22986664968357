import { AppDomainConstant } from "../../app-domain.constant";

export class ConfiguracionCargueArchivoConvenioApiConstant {
    public static readonly API_BASE = 'api/v1/cargue-archivos';

    public static readonly api_dev = 'https://dev-backoffice.konexinnovation.com/'

  public static get API_DOWNLOAD(): string {
      return `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-archivo/descargar-reporte`;
  }

  public static get API_CARGUE_ARCHIVO(): string {
      return `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-archivo`;
  }

  public static get API_CARGUE_REPORTES(): string {
      return `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-convenio`;
  }

  public static get GET_AGREEMENTS(): string {
      return `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-archivo/obtener-convenios`;
  }

  public static get getPreviewUrl(): string {
      return `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-archivo/pre-visualizacion`;
  }

  public static readonly BASE_PREVIEW_URL = `${AppDomainConstant.URI_GATEWAY}${this.API_BASE}/cargue-archivo/pre-visualizacion`;

  public static getAllEndpoints(): string[] {
      return [
          this.API_DOWNLOAD,
          this.API_CARGUE_ARCHIVO,
          this.API_CARGUE_REPORTES,
          this.GET_AGREEMENTS,
          this.BASE_PREVIEW_URL
      ];
  }
}
  