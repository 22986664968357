import { AwsS3APIConstant } from './../constants/apis/aws-s3/AwsS3-api.constant';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerState } from './../states/spinner.state';
import { CargueAPIConstant } from './../constants/apis/cargue/cargue-api.constant';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { EscrutinioAPIConstant } from '../constants/apis/escrutinio/escrutinio-api.constant';
import { ParametrizacionesAPIConstant } from '../constants/apis/parametrizaciones/parametrizaciones-api-constant';
import { SessionStoreUtil } from '../utilities/session-store.util';
import { TipoEventoConstant } from '../constants/tipo-evento.constant';
import { AutenticacionAPIConstant } from '../constants/apis/seguridad/autenticacion-api.constant';
import { AprobacionConfiguracionImpresionAPIConstant } from '../constants/apis/administracion/papeleria/aprobacion-configuracion-impresion/aprobacion-configuracion-impresion-api-constant';
import { ConfiguracionContableProductosApiConstant } from '../constants/apis/administracion/control-operacion/configuracion-contable-productos.constant';

type ObjectInterceptor = {
  'Content-Type': string;
  Authorization?:  string;
}

/**
 * Interceptor que permite configurar la seguridad y el spinner para
 * cada peticion HTTP que realiza el usuario
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  /**
   * @param spinnerState, se utiliza para visualizar, ocultar el spinner
   */
  constructor(private spinnerState: SpinnerState) {}

  /**
   * Metodo que permite capturar cada request del sistema,
   * para asi agregar la seguridad correspondiente a cada peticion
   * con su respectivo spinner
   *
   * @param req, es la solicitud que envia el cliente
   * @param next, es el siguiente interceptor a ejecutar, si aplica
   * @returns Observador con el request modificado
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // se configura el spinner para esta peticion
    this.spinnerState.displaySpinner();
    let contentHeader;
    if (CargueAPIConstant.URL_CARGUE_ARCHIVO_LOTERIA_VIRTUAL !== req.url 
      && AwsS3APIConstant.URL_CARGUE_MANUAL !== req.url && EscrutinioAPIConstant.URL_SAVE_IMAGENES !== req.url
      && !req.url.includes(ParametrizacionesAPIConstant.URL_LOAD_RESOURCE) && !req.url.includes(AprobacionConfiguracionImpresionAPIConstant.URL_GET_COMPROBANTE_FISICO)
      && !req.url.includes(ConfiguracionContableProductosApiConstant.URL_LOAD_RESOURCE)) {
      contentHeader = this.getOnlyTypeJson() ;
    }
    if (AutenticacionAPIConstant.URL_REFRESH_TOKEN == req.url ) {
      contentHeader = this.getOnlyTypeJson() ;
    }
    return next.handle(req.clone({ setHeaders: contentHeader})).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerState.hideSpinner();
          }
        },
        (err: any) => {
          this.spinnerState.hideSpinner();
        }
      )
    );
  }

  /**
   * Metodo que permite crear un Header con solo el tipo de json content
   */
  private getOnlyTypeJson(): any {  
    let object: ObjectInterceptor = {
      'Content-Type': 'application/json;charset=UTF-8'
    };

    if (SessionStoreUtil.auth(TipoEventoConstant.GET))  {
      object = {
        ...object, 
        'Authorization': `Bearer ${ SessionStoreUtil.auth(TipoEventoConstant.GET).idToken }`
      } 
    }     
    return object;
  }
}
