import { AppDomainConstant } from '../app-domain.constant';

/**
 * Clase que contiene todas las constantes de la API para el modulo de AWS-S3
 */
export class AwsS3APIConstant {

  /** Nombre del REST para el modulo RECURSOS  */
  static readonly CARGUE_MANUAL_API: string = 's3/aws/';

  /** PATH que contiene los procesos de negocio para el cargue del manual al RECURSO */
  static readonly URL_CARGUE_MANUAL: string =
    AppDomainConstant.URI_GATEWAY +
    AwsS3APIConstant.CARGUE_MANUAL_API +
    'cargarManualRecurso';
}
