import { Translation } from "primeng/api";

export const translations: Translation = {
  startsWith: "Inicia con",
  contains: "Contiene",
  notContains: "No contiene",
  endsWith: "Finaliza con",
  equals: "Igual",
  notEquals: "Diferente",
  noFilter: "Sin Filtro",
  lt: "Menor que",
  lte: "Menor o igual que",
  gt: "Mayor que",
  gte: "Mayor o igual que",
  is: "Es",
  isNot: "No es",
  before: "Antes",
  after: "Después",
  dateIs: "La fecha es",
  dateIsNot: "No es la fecha",
  dateBefore: "La fecha es anterior",
  dateAfter: "La fecha es posterior",
  clear: "Limpiar",
  apply: "Aplicar",
  matchAll: "Coincidir con todo",
  matchAny: "Coincidir con cualquiera",
  addRule: "Agregar Regla",
  removeRule: "Remover Regla",
  accept: "Aceptar",
  reject: "Cancelar",
  choose: "Elegir",
  upload: "Subir",
  cancel: "Cancelar",
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  firstDayOfWeek: 0,
  today: "Hoy",
  weekHeader: "Se",
  weak: "Débil",
  medium: "Medio",
  strong: "Fuerte",
  passwordPrompt: "Ingrese una contraseña",
  emptyMessage: "Resultados no encontrados",
  emptyFilterMessage: "Resultados no encontrados",
};
